<template>
    <div>
        <modal :name="modal_name" transition="nice-modal-fade" class="final-modal change-course-modal"
            :class="{'modalZindex':safariTrue}">
            <div class="v-modal-content">
                <div class="v-modal-header">
                    <span class="v-modal-dialog-title v-modal-title">Change Course</span>
                </div>
                <div class="v-modal-body">
                    <div class="v-modal-layout">
                        <div class="row pb-2 pl-4 justify-content-between pr-5">
                            <span class="text-secondary font-weight-bold">
                                {{
                courseChangeData.item.product_name
                }}
                            </span>
                            <div class="d-flex">
                                <span v-if="showQty > 1" class="btnqty pr-2 pt-1" @click="decreaseQty()">
                                    <img src="https://cdn.digicollect.com/cdn/posv2/minus-blue.svg"
                                        class="img-add-button-svg" />
                                </span>
                                <span v-else class="btnqty pr-2 pt-1" style="cursor: not-allowed;">
                                    <img src="https://cdn.digicollect.com/cdn/posv2/minus-blue.svg"
                                        class="img-add-button-svg" style="cursor: not-allowed;" />
                                </span>
                                <template>
                                    <input type="number" class="qtyinput" v-model.number="showQty"
                                        @blur="checkshowQty()" />
                                </template>
                                <span v-if="showQty < courseChangeData.item.quantity" class="btnqty pl-2 pt-1" @click="increaseQty()">
                                    <img src="https://cdn.digicollect.com/cdn/posv2/plus-blue.svg"
                                        class="img-add-button-svg" />
                                </span>
                                <span v-else class="btnqty pl-2 pt-1" style="cursor: not-allowed;">
                                    <img src="https://cdn.digicollect.com/cdn/posv2/plus-blue.svg"
                                        style="cursor: not-allowed;" class="img-add-button-svg" />
                                </span>
                            </div>
                        </div>
                        <div class="pb-3" style="margin-top: -17px;">
                            <span class="font-weight-bold">
                                (Ordered Quantity :
                                {{ courseChangeData.item.quantity }})
                            </span>
                        </div>
                        <span class="font-weight-bold">Change Course</span>
                        <div class>
                            <div v-for="(i, index) in courseChangeData.noCourses" :key="index" class="course-padding"
                                :class="{
                                    backgroundColor:
                                        courseChangeData.noCourses[index]
                                            .course ==
                                        changedCourse,
                                        displayHidden:courseChangeData.noCourses[index]
                                            .course ==
                                        courseChangeData.item.course
                                }">
                                <div style="width: 100%;" @click="selectCourseforProduct(i.course)">{{ i.course }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="v-modal-footer text-center pb-5 modal-bg">
                    <a class="btn btn-white btn-smm btn-no-border-blue text-uppercase ml-1"
                        @click="hideCourse()">CANCEL</a>
                    <button class="btn btn-secondary btn-smm text-uppercase" @click="selectCourse()"
                        :disabled="changedCourse == ''">SAVE</button>
                </div>
            </div>
        </modal>
    </div>
</template>
<script>
    import {
        EventBus
    } from './eventBus/nav-bar-event'
    export default {
        props: ['modal_name', 'courseChangeData'],
        data() {
            return {
                modal_msg: '',
                showQty: 0,
                changedCourse: '',
                safariTrue: false
            }
        },
        methods: {
            hideCourse() {
                this.changedCourse = ''
                this.$modal.hide(this.modal_name)
                this.$emit('cancelcourse')
            },
            checkshowQty() {
                if (this.showQty > this.courseChangeData.item.quantity) {
                    this.showQty = this.courseChangeData.item.quantity
                } else if (this.showQty == '' || this.showQty == 0) {
                    this.showQty = 1
                }
            },
            selectCourse() {
                if (this.showQty > 0) {
                    this.selectCourseConfirm()
                } else {
                    this.$toasted.global.error('Quantity should be greater than Zero')
                }
            },
            async selectCourseConfirm() {
                try {
                    let params = {
                        item_id: this.courseChangeData.item.order_item_id,
                        course: this.changedCourse,
                        quantity: this.showQty
                    }
                    let res = await this.changeCourseApi(params)
                    if (res.status_id == 1) {
                        this.changedCourse = ''
                        this.modal_msg = res.message
                        this.$toasted.global.success(this.modal_msg)
                        this.$emit('courseUpdated')
                    }
                } catch (reason) {
                    this.$toasted.global.error(reason)
                }
            },
            increaseQty() {
                let incqty = this.showQty
                if (incqty < this.courseChangeData.item.quantity) {
                    incqty++
                    this.showQty = incqty
                }
                console.log(this.courseChangeData.item.quantity)
                console.log(this.showQty)
            },
            decreaseQty() {
                let decqty = this.showQty
                if (decqty > 1) {
                    decqty--
                    this.showQty = decqty
                }
            },
            selectCourseforProduct(course) {
                this.changedCourse = course
            },
            async changeCourseApi(params) {
                return new Promise(async (resolve, reject) => {
                    let response = await this.$http.post(
                        '/orders/items/course/change',
                        params
                    )
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                })
            }
        },
        watch: {
            courseChangeData(newValue, oldValue) {
                if (newValue) {
                    this.showQty = this.courseChangeData.item.quantity
                }
            }
        },
        mounted() {
            this.showQty = this.courseChangeData.item.quantity
            this.safariTrue = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        }
    }
</script>
<style scoped>
    .backgroundColor {
        background: #00448b !important;
        color: #ffff !important;
    }

    .displayHidden {
        display: none !important;
    }

    .courses {
        float: left;
        display: block;
        color: #00448b;
        border: 1px solid #00448b;
        border-radius: 50% !important;
        padding: 6px 13px;
        margin-left: 10px;
        font-weight: bold;
        font-size: 15px;
        margin-top: 4px;
        cursor: pointer;
    }

    .change-course-modal .course-padding {
        padding: 6px;
        border: 1px solid #00448b;
        border-radius: 50%;
        display: inline-flex;
        text-align: center;
        vertical-align: middle;
        border-radius: 50%;
        margin: 10px;
        width: 33px;
        cursor: pointer;
        color: #00448b;
        font-weight: 600;
    }

    .qtyinput:focus {
        border: 0px solid #e6e6e6 !important;
        outline: 0 !important;
    }
</style>